import React, { Fragment } from 'react';
import BusinessStats from '../../Components/BusinessStats/BusinessStats';
import HeroSection from '../../Components/HeroSection/HeroSection';
import Solutions from '../../Components/Solutions/Solutions';
import WhoWeAre from '../../Components/WhoWeAre/WhoWeAre';
import './Home.css';

const Home = () => {
  return (
    <Fragment>
      <HeroSection />
      <div className='established_div'>
        <h6><b>Revolutionizing Parking for the Drivers of Today</b></h6>
      </div>
      <Solutions />
      <WhoWeAre />
      <BusinessStats />
    </Fragment>
  )
}

export default Home