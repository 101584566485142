import React from 'react';
import { Container } from 'react-bootstrap';
import './TermsCondition.css';

const TermsCondition = () => {
  return (
    <div className='terms_condition'>
      <Container>
        <h1>Smart Parking License Agreement(s)</h1>
        <h5>Terms and Conditions for use of Parking Cards</h5>

        <ul className='main_terms_ul'>
          <li> Introduction
            <p>These Terms and Conditions read with the applicable parking bye-laws/regulations govern the use of Parking Cards
              (hereinafter referred to as "Parking Cards") issued by AJCL (Private) Limited (hereinafter referred to as the "Operator")
              on behalf of Capital Development Authority and Metropolitan Corporation of Islamabad (hereinafter collectively
              referred to as the "Authority"). By using the Parking Card, you (hereinafter referred to as the "User")
              agree to comply with these Terms and Conditions. </p>
            <p>If you do not agree with any part of these Terms and Conditions, you must refrain from using the Parking Cards.</p>
          </li>
          <li>
            Issuance and Ownership of Parking Cards

            <ul>
              <li>Parking Cards are issued by the Operator on behalf of the Authority and remain the property of the Authority at all times.</li>
              <li>Parking Cards are issued to the User for the sole purpose of accessing parking facilities operated by the Operator on behalf of the Authority.</li>
              <li>The User is responsible for ensuring the security and proper use of the Parking Cards.</li>
              <li>The Parking Cards are non-transferable and must not be used by any person other than the User to whom they are issued.</li>
            </ul>
          </li>
          <li>
            Use of Parking Cards

            <ul>
              <li>The User is responsible for the safety and secure custody of the Parking Card.</li>
              <li>The Parking Cards may only be used in designated parking areas managed by the Operator on behalf of the Authority.</li>
              <li>The User must adhere to all applicable parking bye-laws, regulations and guidelines as set forth by the Authority and the Operator.</li>
              <li>The Parking Card entitles the User to park in designated areas subject to availability. The Operator does not guarantee the availability of parking spaces at any time.</li>
              <li>The Operator reserves the right to refuse entry to any vehicle that does not comply with the facility's rules, including but not limited to size, weight, and height restrictions.</li>
              <li>The User is responsible for ensuring that their vehicle is parked within the designated parking bay and complies with any instructions provided by the Operator or the Authority.</li>
            </ul>
          </li>

          <li>
            Payment and Charges

            <ul>
              <li>The Parking Charges are determined by the Authority and the current charges will be displayed at the Parking Facility and/or on the Operator’s website.</li>
              <li>The User is required to pay all applicable fees and charges for the use of parking facilities as determined by the Authority and collected by the Operator. </li>
              <li>The fees and charges for parking services may be subject to change by the Authority. The Authority /Operator will provide reasonable notice to Users of any changes to the fees and charges.</li>
              <li>The Parking Card may be subject to pre-payment or may function on a pay-as-you-go basis.</li>
              <li>The Parking Card may also be linked to an account from which parking fees will be automatically deducted. The User is responsible for maintaining sufficient funds or credit in the linked account to cover parking charges or parking fines.</li>
              <li>Any parking fines may be paid through the Parking Card.</li>
              <li>Failure to pay parking fees or charges may result in penalties, including but not limited to fines, suspension of Parking Card privileges, and legal action.</li>
            </ul>
          </li>

          <li>
            Expiration and Renewal

            <ul>
              <li>Parking Cards are valid for a specific period as determined by the Authority. The expiration date will be indicated on the Parking Card or associated documentation.</li>
              <li>The User is responsible for renewing the Parking Card before its expiration. The Operator may send reminders to the User, but it is ultimately the User's responsibility to ensure timely renewal. </li>
              <li>The Operator reserves the right to refuse renewal or to cancel the Parking Card if the User is found to be in violation of these Terms and Conditions and or any violation of the Parking Bye-laws or regulations to which the User is subject.</li>
            </ul>
          </li>

          <li>
            Cancellation and Suspension

            <ul>
              <li>The Operator may cancel or suspend the Parking Card at any time if the User breaches any of these Terms and Conditions, uses the Parking Card fraudulantly, or fails to comply with parking bye-laws and or regulations.</li>
              <li>The User may cancel the Parking Card by providing written notice to the Operator. Any fees paid in advance are non-refundable unless otherwise specified by the Authority. </li>
              <li>If the Parking Card remains unused for a period exceeding 12 (twelve) months, any balance amount of the Parking Card will be forfeited and the same shall not be refundable.</li>
            </ul>
          </li>

          <li>
            Liability

            <ul>
              <li>The Operator or the Authority is not liable for any loss, theft, or unauthorized use of the Parking Card.</li>
              <li>The User acknowledges that the use of parking facilities is at their own risk. The Authority and the Operator shall not be liable for any loss, damage, or injury incurred while using the parking facilities.</li>
              <li>The Authority and the Operator shall not be liable for any loss or damage to the User’s vehicle or its contents while parked in designated parking areas.</li>
            </ul>
          </li>

          <li>
            Amendments to Terms and Conditions

            <ul>
              <li>The Authority and the Operator reserve the right to amend these Terms and Conditions at any time. Any amendments will be communicated to the User through appropriate channels, including but not limited to, the Operator’s website, the Parking App or direct communication.</li>
              <li>Continued use of the Parking Card after amendments to these Terms and Conditions constitutes acceptance of the revised Terms and Conditions.</li>
            </ul>
          </li>

          <li>
            Data Protection

            <ul>
              <li>The Operator will process any personal data provided by the User in accordance with applicable data protection laws.</li>
              <li>The User’s personal data may be shared with the Authority for administrative purposes related to the management of the Parking Facility.</li>
            </ul>
          </li>

          <li>
            Governing Law

            <ul>
              <li>These Terms and Conditions shall be governed by and construed in accordance with the laws of Islamic Republic of Pakistan. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Islamabad.</li>
            </ul>
          </li>

          <li>
            Miscellaneous

            <ul>
              <li>If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, such provision shall be severed from the remainder of the Terms and Conditions, which shall remain in full force and effect.</li>
              <li>These Terms and Conditions constitute the entire agreement between the User and the Operator/Authority regarding the use of the Parking Card.</li>
            </ul>
          </li>
        </ul>
      </Container>

      <Container className='mt-5'>
        <h1>اسمارٹ پارکنگ لائسنس معاہدہ(جات)</h1>
        <h5>پارکنگ کارڈز کے استعمال کے لیے شرائط و ضوابط</h5>

        <ul className='main_terms_ul_urdu'>
          <li>
            تعارف
            <p>یہ شرائط و ضوابط، متعلقہ پارکنگ بائی لاز/قوانین کے ساتھ مل کر، پارکنگ کارڈز کے استعمال کو منظم کرتی ہیں جو کہ اے جے سی ایل (پرائیویٹ) لمیٹڈ (جسے یہاں "آپریٹر" کہا جائے گا) کی جانب سے کیپیٹل ڈویلپمنٹ اتھارٹی اور میٹروپولیٹن کارپوریشن اسلام آباد (جسے یہاں "اتھارٹی" کہا جائے گا) کی طرف سے جاری کیے جاتے ہیں۔ پارکنگ کارڈز کا استعمال کرتے ہوئے، آپ (جسے یہاں "صارف" کہا جائے گا) ان شرائط و ضوابط کی پابندی کرنے سے اتفاق کرتے ہیں۔ اگر آپ ان شرائط و ضوابط کے کسی حصے سے متفق نہیں ہیں، تو آپ کو پارکنگ کارڈز کے استعمال سے باز رہنا چاہیے۔</p>
          </li>

          <li>
            پارکنگ کارڈز کا اجرا اور ملکیت
            <p>پارکنگ کارڈز آپریٹر کی جانب سے اتھارٹی کی طرف سے جاری کیے جاتے ہیں اور ہمیشہ اتھارٹی کی ملکیت رہیں گے۔ پارکنگ کارڈز صارف کو صرف اس مقصد کے لیے جاری کیے جاتے ہیں کہ وہ اتھارٹی کی طرف سے آپریٹر کے زیرِ انتظام پارکنگ سہولیات تک رسائی حاصل کر سکے۔ صارف پر یہ ذمہ داری ہے کہ وہ پارکنگ کارڈز کی حفاظت اور مناسب استعمال کو یقینی بنائے۔ پارکنگ کارڈز ناقابلِ منتقلی ہیں اور ان کا استعمال کسی ایسے شخص کی جانب سے نہیں کیا جا سکتا جس کو یہ جاری نہیں کیے گئے۔</p>
          </li>

          <li>
            پارکنگ کارڈز کا استعمال
            <p>صارف پارکنگ کارڈ کی حفاظت اور محفوظ تحویل کا ذمہ دار ہے۔ پارکنگ کارڈز صرف ان مخصوص پارکنگ علاقوں میں استعمال کیے جا سکتے ہیں جو آپریٹر کی جانب سے اتھارٹی کی طرف سے زیرِ انتظام ہیں۔ صارف کو اتھارٹی اور آپریٹر کی طرف سے مقرر کردہ تمام متعلقہ پارکنگ بائی لاز، قواعد و ضوابط اور ہدایات کی پابندی کرنی ہوگی۔ پارکنگ کارڈ صارف کو مخصوص علاقوں میں پارکنگ کی اجازت دیتا ہے، بشرطیکہ جگہ دستیاب ہو۔ آپریٹر کسی بھی وقت پارکنگ کی جگہ کی دستیابی کی ضمانت نہیں دیتا۔ آپریٹر کو کسی بھی ایسے گاڑی کو داخلے سے انکار کرنے کا حق حاصل ہے جو سہولت کے قواعد کی تعمیل نہیں کرتی، بشمول لیکن محدود نہیں سائز، وزن، اور اونچائی کی پابندیوں تک۔ صارف اس بات کا ذمہ دار ہے کہ وہ اپنی گاڑی کو مخصوص پارکنگ بی میں کھڑا کرے اور آپریٹر یا اتھارٹی کی جانب سے دی گئی کسی بھی ہدایت کی تعمیل کرے۔</p>
          </li>

          <li>
            ادائیگی اور چارجز
            <p>پارکنگ چارجز کا تعین اتھارٹی کی طرف سے کیا جاتا ہے اور موجودہ چارجز پارکنگ سہولت پر اور/یا آپریٹر کی ویب سائٹ پر ظاہر کیے جائیں گے۔ صارف پر لازم ہے کہ وہ اتھارٹی کی جانب سے مقرر کردہ پارکنگ سہولت کے استعمال کے تمام متعلقہ فیس اور چارجز ادا کرے جو آپریٹر کے ذریعے وصول کیے جائیں گے۔ پارکنگ سروسز کی فیس اور چارجز اتھارٹی کی طرف سے تبدیل کیے جا سکتے ہیں۔ اتھارٹی/آپریٹر صارفین کو فیس اور چارجز میں ہونے والی کسی بھی تبدیلی کے بارے میں مناسب نوٹس فراہم کریں گے۔ پارکنگ کارڈ پیشگی ادائیگی پر مشتمل ہو سکتا ہے یا پے ایز یو گو (Pay-As-You-Go) کی بنیاد پر کام کر سکتا ہے۔ پارکنگ کارڈ ایک ایسے اکاؤنٹ سے بھی منسلک ہو سکتا ہے جس سے پارکنگ فیس خود بخود کٹ جائے گی۔ صارف پر لازم ہے کہ وہ منسلک اکاؤنٹ میں کافی فنڈز یا کریڈٹ برقرار رکھے تاکہ پارکنگ چارجز یا جرمانے کی ادائیگی ہو سکے۔ کسی بھی پارکنگ جرمانے کی ادائیگی پارکنگ کارڈ کے ذریعے کی جا سکتی ہے۔ پارکنگ فیس یا چارجز کی عدم ادائیگی پر جرمانے عائد ہو سکتے ہیں، جن میں لیکن محدود نہیں جرمانے، پارکنگ کارڈ کے استحقاق کی معطلی، اور قانونی کارروائی شامل ہیں۔</p>
          </li>

          <li>
            خاتمہ اور تجدید

            <p>پارکنگ کارڈز ایک مخصوص مدت کے لیے کارآمد ہوتے ہیں جس کا تعین اتھارٹی کی طرف سے کیا جاتا ہے۔ اختتامی تاریخ پارکنگ کارڈ یا اس سے منسلک دستاویزات پر درج ہوگی۔ صارف کی ذمہ داری ہے کہ وہ پارکنگ کارڈ کی میعاد ختم ہونے سے پہلے اس کی تجدید کرے۔ آپریٹر صارف کو یاددہانی بھیج سکتا ہے، لیکن بروقت تجدید کو یقینی بنانا بالآخر صارف کی ذمہ داری ہے۔ اگر صارف کو ان شرائط و ضوابط یا کسی پارکنگ بائی لاز یا قوانین کی خلاف ورزی میں پایا گیا، تو آپریٹر تجدید سے انکار کرنے یا پارکنگ کارڈ منسوخ کرنے کا حق محفوظ رکھتا ہے۔</p>
          </li>

          <li>
            منسوخی اور معطلی
            <p>اگر صارف ان شرائط و ضوابط کی کسی بھی خلاف ورزی کرتا ہے، پارکنگ کارڈ کا دھوکہ دہی سے استعمال کرتا ہے، یا پارکنگ بائی لاز اور/یا قوانین کی پیروی میں ناکام رہتا ہے، تو آپریٹر کسی بھی وقت پارکنگ کارڈ کو منسوخ یا معطل کر سکتا ہے۔ صارف پارکنگ کارڈ کو آپریٹر کو تحریری نوٹس دے کر منسوخ کر سکتا ہے۔ پہلے سے ادا کی گئی کوئی بھی فیس ناقابل واپسی ہے، سوائے اس کے کہ اتھارٹی کی طرف سے کوئی اور وضاحت کی گئی ہو۔ اگر پارکنگ کارڈ 12 (بارہ) ماہ سے زیادہ مدت تک استعمال نہ کیا جائے تو پارکنگ کارڈ کی کوئی بھی بیلنس رقم ضبط کر لی جائے گی اور یہ رقم ناقابل واپسی ہوگی۔</p>
          </li>

          <li>
            ذمہ داری
            <p>آپریٹر یا اتھارٹی پارکنگ کارڈ کے کسی نقصان، چوری، یا غیر مجاز استعمال کے لیے ذمہ دار نہیں ہے۔ صارف تسلیم کرتا ہے کہ پارکنگ سہولیات کا استعمال اس کی اپنی ذمہ داری پر ہے۔ اتھارٹی اور آپریٹر پارکنگ سہولیات کے استعمال کے دوران ہونے والے کسی بھی نقصان، نقصان یا چوٹ کے ذمہ دار نہیں ہوں گے۔ اتھارٹی اور آپریٹر صارف کی گاڑی یا اس کے اندر موجود اشیاء کو مخصوص پارکنگ علاقوں میں کھڑے ہونے کے دوران ہونے والے کسی نقصان یا چوری کے لیے ذمہ دار نہیں ہوں گے۔</p>
          </li>

          <li>
            شرائط و ضوابط میں ترمیم
            <p>اتھارٹی اور آپریٹر کسی بھی وقت ان شرائط و ضوابط میں ترمیم کرنے کا حق محفوظ رکھتے ہیں۔ کسی بھی ترمیم کو صارف کو مناسب ذرائع کے ذریعے مطلع کیا جائے گا، جن میں آپریٹر کی ویب سائٹ، پارکنگ ایپ یا براہ راست مواصلات شامل ہیں لیکن ان تک محدود نہیں۔ ترمیم شدہ شرائط و ضوابط کے بعد پارکنگ کارڈ کا مسلسل استعمال ان میں تبدیلیوں کو قبول کرنے کے مترادف ہوگا۔</p>
          </li>

          <li>
            ڈیٹا پروٹیکشن
            <p>آپریٹر صارف کی فراہم کردہ کسی بھی ذاتی معلومات کو متعلقہ ڈیٹا پروٹیکشن قوانین کے مطابق پروسیس کرے گا۔ صارف کے ذاتی ڈیٹا کو اتھارٹی کے ساتھ انتظامی مقاصد کے لیے شیئر کیا جا سکتا ہے، جو پارکنگ سہولیات کے انتظام سے متعلق ہوں۔</p>
          </li>

          <li>
            قانون کی عملداری
            <p>یہ شرائط و ضوابط اسلامی جمہوریہ پاکستان کے قوانین کے مطابق چلائی جائیں گی اور ان کی تشریح کی جائے گی۔ ان شرائط و ضوابط سے پیدا ہونے والے یا ان سے متعلق کسی بھی تنازع کا اختیار اسلام آباد کی عدالتوں کے پاس ہوگا۔</p>
          </li>

          <li>
            متفرق
            <p>اگر کسی قابلِ اختیار عدالت کے ذریعے ان شرائط و ضوابط کی کوئی شق غیر قانونی یا ناقابلِ عمل قرار دی جاتی ہے، تو ایسی شق کو باقی شرائط و ضوابط سے علیحدہ سمجھا جائے گا، اور باقی شرائط و ضوابط پوری قوت اور اثر کے ساتھ نافذ العمل رہیں گے۔ یہ شرائط و ضوابط صارف اور آپریٹر/اتھارٹی کے درمیان پارکنگ کارڈ کے استعمال سے متعلق مکمل معاہدے کی نمائندگی کرتے ہیں۔</p>
          </li>
        </ul>
      </Container>

      <Container>
        <div className='contact_info'>
          <h6>Contact Information</h6>
          <p style={{ marginBottom: "20px" }}>For any inquiries or issues related to the use of Parking Cards, please contact the Operator at:</p>

          <p>AJCL (Private) Limited</p>
          <p>Pak G-8 Markaz G 8 Markaz G-8, Islamabad Capital Territory, Pakistan</p>
          <p>+92 21 111 252 575</p>
          <p>ajcl.khi@ajcl.net</p>
        </div>
      </Container>
    </div>
  )
}

export default TermsCondition