import { combineReducers } from "redux";
import { GetParkingPaymentQRReducer, GetScratchCardVerifyReducer, GetTicketInfoReducer, PaymentSuccessReducer, PaymentWithCardReducer } from "./Payment";

const rootReducer = combineReducers({

    ticketInfoGet: GetTicketInfoReducer,
    scratchCardVerifyGet: GetScratchCardVerifyReducer,
    paymentSuccessGet: PaymentSuccessReducer,
    paymentWithCardGet: PaymentWithCardReducer,
    parkingPaymentQRGet: GetParkingPaymentQRReducer

})

export default rootReducer;