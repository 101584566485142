import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import hardwareImg from "../../images/solutions/hardware_img.png";
import parkingExpIcon1 from "../../images/solutions/parking_exp_icon1.png";
import parkingExpIcon2 from "../../images/solutions/parking_exp_icon2.png";
import parkingExpIcon3 from "../../images/solutions/parking_exp_icon3.png";
import parkingExpIcon4 from "../../images/solutions/parking_exp_icon4.png";
import ParkingRightImg from "../../images/solutions/parking_exp_right.png";
import parkingSolIcon1 from "../../images/solutions/parking_solution_icon1.jpg";
import parkingSolIcon2 from "../../images/solutions/parking_solution_icon2.jpg";
import parkingSolIcon3 from "../../images/solutions/parking_solution_icon3.jpg";
import solutionBannerRight from "../../images/solutions/solution_banner.png";
import './Solutions.css';

const Solutions = () => {
    return (
        <div className='solutions_main'>
            <div className='solution_banner'>
                <Container>
                    <Row className='justify-content-between align-items-center'>
                        <Col md={6}>
                            <h3>Transforming the</h3>
                            <h1>Future of Parking</h1>
                            <p>Outdated parking systems lead to delays, errors, and inefficient space management,
                                frustrating customers and reducing revenue. Our AI-driven parking management system solves this
                                with automated license plate recognition for quick entry and exit, real-time space optimization,
                                and multiple payment options. With secure data storage and streamlined operations, we enhance
                                efficiency and boost profitability.</p>
                        </Col>
                        <Col md={5}>
                            <img src={solutionBannerRight} alt='' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='innovative_solution_main'>
                <Container>
                    <Row>
                        <Col md={5}>
                            <h3>Streamlining Parking <br />
                                with <span> Innovative <br /> Solutions </span> </h3>
                        </Col>
                        <Col md={7}>
                            <div className='innovative_sol_right'>
                                <Row>
                                    <Col md={3} xs={6}>
                                        <div>
                                            <img src={parkingSolIcon1} alt='' />
                                            <h6>Automated License
                                                Plate Recognition</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div>
                                            <img src={parkingSolIcon2} alt='' />
                                            <h6>Real-Time Space
                                                Optimization</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div>
                                            <img src={parkingSolIcon3} alt='' />
                                            <h6>Flexible Payment
                                                Options</h6>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div>
                                            <img src={parkingSolIcon3} alt='' />
                                            <h6>Robust Data <br />
                                                Protection</h6>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='seemless_parking_exp'>
                <Container>
                    <Row className='justify-content-between align-items-center'>
                        <Col md={6}>
                            <div className='seemless_parking_exp_left'>
                                <h6>Seamless</h6>
                                <h1>Parking <br /> Experience</h1>

                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <img src={parkingExpIcon1} alt='' />
                                            <h5>Smart Entry</h5>
                                            <p>As you arrive, our AI cameras instantly recognize your license plate,
                                                allowing seamless gate entry.</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <img src={parkingExpIcon2} alt='' />
                                            <h5>Secure Parking Data</h5>
                                            <p>Your vehicle’s entry is logged and safely stored in our system for a hassle-free experience.</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <img src={parkingExpIcon3} alt='' />
                                            <h5>Flexible Payment</h5>
                                            <p>Pay for your parking with ease by selecting from a range of payment options.</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <img src={parkingExpIcon4} alt='' />
                                            <h5>Quick Exit</h5>
                                            <p>When you're ready to leave, the AI cameras will automatically open the gate after scanning your license plate.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={5}>
                            <img src={ParkingRightImg} alt='' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='smart_solution_hardware'>
                <Container>
                    <Row className='justify-content-between align-items-center'>
                        <Col md={5}>
                            <div className='smart_solution_hardware_left'>
                                <h5>Smart</h5>
                                <h1>Parking  <br />  <span>Solutions</span></h1>
                                <img src={hardwareImg} alt='' />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='smart_soluton_hardware_right'>
                                <h6>Features:</h6>

                                <Row>
                                    <Col md={6}>
                                        <h4>Real-Time <br /> License Plate <br /> Recognition </h4>
                                        <p>Instant detection and
                                            identification of vehicle
                                            plates.</p>
                                    </Col>
                                    <Col md={6}>
                                        <h4>License Plate <br /> and Vehicle <br /> Capture</h4>
                                        <p>Captures both the plate and car image.</p>
                                    </Col>
                                    <Col md={6}>
                                        <h4>High <br />
                                            Accuracy <br />
                                            Performance </h4>
                                        <p>Reliable performance with
                                            minimal false positives.</p>
                                    </Col>
                                    <Col md={6}>
                                        <h4>Automated <br />
                                            Payment <br />
                                            Calculation</h4>
                                        <p>Calculates payment based on entry/exit times for smooth departures.</p>
                                    </Col>
                                    <Col md={6}>
                                        <h4>Global <br /> Compatibility </h4>
                                        <p>Supports multi-country license plate formats.</p>
                                    </Col>
                                    <Col md={6}>
                                        <h4>Cloud-Based <br /> Storage </h4>
                                        <p>Secure cloud storage for all
                                            vehicle and payment data.</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='transforming_parking_sol'>
                <Container>
                    <h6>Transforming</h6>
                    <h1>PARKING SOLUTION</h1>
                    <p>Cutting-edge hardware for modern parking experience.</p>
                </Container>
            </div>
        </div>
    )
}

export default Solutions