import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import legacyIcon5 from "../../images/about/agriculture_icon.png";
import legacyIcon1 from "../../images/about/aviation_icon.png";
import legacyIcon3 from "../../images/about/construction_icon.png";
import legacyIcon2 from "../../images/about/oil_gas_icon.png";
import ParkRightImg from "../../images/about/park_smart_right_img.png";
import legacyIcon6 from "../../images/about/power_icon.png";
import legacyIcon4 from "../../images/about/shipping_icon.png";
import AboutRight from "../../images/about/our_mission_right.png";
import parkingSiteLeftImg from "../../images/about/about_last_left.png";
import parkingSiteRightImg from "../../images/about/about_last_right.png";
import CountUp from 'react-countup';
import './About.css';

const About = () => {
  return (
    <div className='about_main'>
      <div className='legacy_vision'>
        <Container>
          <Row className='justify-content-between align-items-center'>
            <Col md={5}>
              <h1> <span>Our</span> <br /> Legacy <br /> <span style={{ fontSize: "60px", fontWeight: 400 }}>&</span> Vision </h1>
              <Row style={{ gap: "20px 0" }}>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon1} alt='' />
                    <h6>Aviation</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon2} alt='' />
                    <h6>Oil & Gas</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon3} alt='' />
                    <h6>Machinery</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon4} alt='' />
                    <h6>Ports & <br /> Shipping</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon5} alt='' />
                    <h6>Commodities <br /> Trading</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='legacy_business'>
                    <img src={legacyIcon6} alt='' />
                    <h6>Power <br /> Generation</h6>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div>
                <p><span>Smart Parking</span> is a project by <span>AJCL</span>, a  business that operates under AJCL Private Limited.
                  The company's origins in Pakistan date back to 1949. It was founded by <span>Mr. Ahmed E. H. Jaffer</span>,
                  a prominent businessman and close associate of Pakistan's founder, <span>Mr. Muhammad Ali Jinnah</span>. </p>

                <p>Together, they played key roles in the creation of Pakistan, and Mr. Jaffer established the company after migrating from
                  pre-partition India.</p>

                <p>Originally part of the larger Jaffer Group, AJCL has operated independently for a long time.
                  Over the past decade, it has experienced rapid international growth, building on its notable successes</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='park_smart'>
        <Container>
          <Row className='justify-content-between align-items-center'>
            <Col md={5}>
              <div>
                <p>Simplify <b>Parking</b>, Enrich Your Experience.</p>
                <h1>Park Smart!</h1>
                <p>Welcome to <b>Smart Parking</b>, your trusted partner in innovative parking solutions. We are dedicated to simplifying parking experiences for both businesses and drivers through cutting-edge technology and customer centric services.</p>
                <h6>Together, we’re paving the way for a smarter, more connected future in parking.</h6>
              </div>
            </Col>
            <Col md={6} className='px-0'>
              <img src={ParkRightImg} alt='' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='company_stats'>
        <Container>
          <h3>Our numbers speaks for itself</h3>
          <Row className='justify-content-center text-center'>
            <Col md={2}>
              <div>
                <h2><CountUp start={0} end={80} duration={5} decimal="," />+</h2>
                <span>Active Parking Sites</span>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <h2>$<CountUp start={0} end={100} duration={5} decimal="," />M</h2>
                <span>Revenue</span>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <h2><CountUp start={0} end={89} duration={5} decimal="," />%</h2>
                <span>Customer Satisfaction</span>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <h2><CountUp start={0} end={60} duration={5} decimal="," />+</h2>
                <span>Ongoing Projects</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='get_know'>
        <Container>
          <h3>Get To Know Us</h3>
          <p>At Smart Parking, we specialize in smart parking management systems that streamline
            parking operations, maximize space utilization, and enhance convenience. Whether you're managing
            a small lot or a multi-level parking facility, our solutions are designed to make parking seamless,
            efficient, and hassle-free.</p>
        </Container>
      </div>
      <div className='our_mission'>
        <Container>
          <Row className='align-items-center'>
            <Col md={6}>
              <div>
                <h3>Our Mission</h3>
                <p>Our mission is to revolutionize parking by offering modern, easy-to-use systems that meet the evolving needs
                  of cities, businesses, and drivers alike. We aim to reduce traffic congestion, optimize parking spaces, and
                  improve accessibility for everyone through sustainable, tech-driven solutions.</p>
              </div>
            </Col>
            <Col md={6}>
              <img src={AboutRight} alt='' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='parking_sites'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col md={4} className='ps-0'>
              <img src={parkingSiteLeftImg} alt='' />
            </Col>
            <Col md={4}>
              <h2>We’ve got over <br /><span>75 parking sites</span> <br /> and counting, <br /> finding your spot has never been easier!</h2>
            </Col>
            <Col md={4} className='ps-0 pe-0'>
              <img src={parkingSiteRightImg} alt='' />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default About;