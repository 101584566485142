import { fetchApi } from "../../Util/Helper";

export const GetTicketInfo = (tokenNo) => async (dispatch) => {
    try {
        dispatch({
            type: "GET_TICKET_INFO_REQUEST",
        });

        const formData = new FormData();
        formData.append("parkingTokenNumber", JSON.stringify(tokenNo))

        const data = await fetchApi({
            method: 'post',
            endPoint: 'parkingApp/getTicketInfo/',
            token: true,
            data: formData,
            params: false,
            formData: true
        });

        if (data) {
            dispatch({
                type: "GET_TICKET_INFO_SUCCESS",
                payload: data?.data,
                success: true,
            });
        }

    }
    catch (e) {
        dispatch({
            type: "GET_TICKET_INFO_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const ScratchCardVerify = (queryParams) => async (dispatch) => {
    try {
        dispatch({
            type: "SCRATCH_CARD_VERIFY_REQUEST",
        });

        const data = await fetchApi({
            method: 'get',
            endPoint: 'parkingScratchCard/verify/',
            token: true,
            data: null,
            params: queryParams,
            formData: false
        });

        if (data) {
            dispatch({
                type: "SCRATCH_CARD_VERIFY_SUCCESS",
                payload: data?.data,
                success: true,
            });
        }

    }
    catch (e) {
        dispatch({
            type: "SCRATCH_CARD_VERIFY_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const PaymentSuccess = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: "PAYMENT_SUCCESS_REQUEST",
        });

        const data = await fetchApi({
            method: 'put',
            endPoint: 'parkingScratchCard/paymentSuccess/',
            token: true,
            data: formData,
            params: false,
            formData: true
        });

        if (data) {
            dispatch({
                type: "PAYMENT_SUCCESS_SUCCESS",
                payload: data?.data,
                success: true,
            });
        }

    }
    catch (e) {
        dispatch({
            type: "PAYMENT_SUCCESS_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const PaymentWithCard = (params) => async (dispatch) => {
    try {
        dispatch({
            type: "PAYMENT_WITH_DEBIT_CARD_REQUEST",
        });

        const data = await fetchApi({
            method: 'get',
            endPoint: 'parkingApp/getBankCardGatewayUrl',
            token: true,
            data: null,
            params: params,
            formData: false
        });

        if (data) {
            dispatch({
                type: "PAYMENT_WITH_DEBIT_CARD_SUCCESS",
                payload: data?.data,
                success: true,
            });
        }

    }
    catch (e) {
        dispatch({
            type: "PAYMENT_WITH_DEBIT_CARD_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const GetParkingPaymentQR = (params) => async (dispatch) => {
    try {
        dispatch({
            type: "GET_PARKING_PAYMENT_QR_REQUEST",
        });

        const data = await fetchApi({
            method: 'get',
            endPoint: 'parkingApp/getParkingPaymentQR',
            token: true,
            data: null,
            params: params,
            formData: false
        });

        if (data) {
            dispatch({
                type: "GET_PARKING_PAYMENT_QR_SUCCESS",
                payload: data?.data,
                success: true,
            });
        }

    }
    catch (e) {
        dispatch({
            type: "GET_PARKING_PAYMENT_QR_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};