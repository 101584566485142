import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { bikeDarkIcon, busDarkIcon, carDarkIcon, vanDarkIcon } from '../../Util/Svgs';
import './Pricing.css';

const Pricing = () => {
    const priceData = [
        { id: 1, name: 'Bike', icon: bikeDarkIcon, price: 50, text: "Quick and easy parking for two-wheelers." },
        { id: 2, name: 'Car', icon: carDarkIcon, price: 100, text: "Spacious spots designed for your comfort." },
        { id: 3, name: 'Van', icon: vanDarkIcon, price: 200, text: "Ample space for large vehicles." },
        { id: 4, name: 'Bus', icon: busDarkIcon, price: 500, text: "Perfect for vans and larger family vehicles." }
    ]
    return (
        <div className='pricing_main'>
            <Container>
                <h1>Convenient and Affordable <br />
                    Parking for All Vehicles!</h1>
                <p>Enjoy safe and secure parking at competitive rates. <br />
                    Whether you're arriving on a bike, in a car, or with a larger vehicle, we've got you covered</p>

                <Row className='justify-content-center'>
                    {
                        priceData.map((d) => {
                            return (
                                <Col md={3} xs={10} key={d.id}>
                                    <div className='price_box'>
                                        <div>
                                            <h2>{d.name}</h2>
                                            <div>
                                                {d.icon}
                                                <h6>{d.name}</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>{d.price}<span>Rs.</span></h3>
                                            <p>{d.text}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>

                <p className='mt-5'>Park with ease, knowing your vehicle is in good hands!</p>
            </Container>
        </div>
    )
}
export default Pricing;