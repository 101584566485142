import axios from "axios";

export const fetchApi = async ({ method, endPoint, token, data, params, formData }) => {
    const headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + (`${process.env.REACT_APP_AUTH_TOKEN}`);
    }

    if (formData) {
        headers['Content-Type'] = 'multipart/form-data';
        headers['Accept'] = 'multipart/form-data';
    } else {
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json';
    }
    const config = {
        method,
        url: `${process.env.REACT_APP_API_URL}/${endPoint}`,
        headers,
        data: data ? data : undefined,
    };

    if (params) config.params = params;
    return await axios(config);
};

