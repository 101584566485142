export const GetTicketInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case "GET_TICKET_INFO_REQUEST":
            return {
                loading: true,
                error: false
            }
        case "GET_TICKET_INFO_SUCCESS":
            return {
                ...state,
                loading: false,
                getTicketInfoData: action.payload,
                error: false
            }
        case "GET_TICKET_INFO_FAILED":
            return {
                ...state,
                loading: false,
                getTicketInfoData: null,
                error: action.payload
            }
        case "GET_TICKET_INFO_RESET":
            return {
                ...state,
                getTicketInfoData: null,
                error: null
            }
        default:
            return state
    }
}

export const GetScratchCardVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case "SCRATCH_CARD_VERIFY_REQUEST":
            return {
                loading: true,
                error: false
            }
        case "SCRATCH_CARD_VERIFY_SUCCESS":
            return {
                ...state,
                loading: false,
                getScratchCardVerifyData: action.payload,
                error: false
            }
        case "SCRATCH_CARD_VERIFY_FAILED":
            return {
                ...state,
                loading: false,
                getScratchCardVerifyData: null,
                error: action.payload
            }
        case "SCRATCH_CARD_VERIFY_RESET":
            return {
                ...state,
                getScratchCardVerifyData: null,
                error: null
            }
        default:
            return state
    }
}

export const PaymentSuccessReducer = (state = {}, action) => {
    switch (action.type) {
        case "PAYMENT_SUCCESS_REQUEST":
            return {
                loading: true,
                error: false
            }
        case "PAYMENT_SUCCESS_SUCCESS":
            return {
                ...state,
                loading: false,
                getPaymentSuccessData: action.payload,
                error: false
            }
        case "PAYMENT_SUCCESS_FAILED":
            return {
                ...state,
                loading: false,
                getPaymentSuccessData: null,
                error: action.payload
            }
        case "PAYMENT_SUCCESS_RESET":
            return {
                ...state,
                getPaymentSuccessData: null,
                error: null
            }
        default:
            return state
    }
}

export const PaymentWithCardReducer = (state = {}, action) => {
    switch (action.type) {
        case "PAYMENT_WITH_DEBIT_CARD_REQUEST":
            return {
                loading: true,
                error: false
            }
        case "PAYMENT_WITH_DEBIT_CARD_SUCCESS":
            return {
                ...state,
                loading: false,
                getPaymentWithCardData: action.payload,
                error: false
            }
        case "PAYMENT_WITH_DEBIT_CARD_FAILED":
            return {
                ...state,
                loading: false,
                getPaymentWithCardData: null,
                error: action.payload
            }
        case "PAYMENT_WITH_DEBIT_CARD_RESET":
            return {
                ...state,
                getPaymentWithCardData: null,
                error: null
            }
        default:
            return state
    }
}

export const GetParkingPaymentQRReducer = (state = {}, action) => {
    switch (action.type) {
        case "GET_PARKING_PAYMENT_QR_REQUEST":
            return {
                loading: true,
                error: false
            }
        case "GET_PARKING_PAYMENT_QR_SUCCESS":
            return {
                ...state,
                loading: false,
                getQRCodeData: action.payload,
                error: false
            }
        case "GET_PARKING_PAYMENT_QR_FAILED":
            return {
                ...state,
                loading: false,
                getQRCodeData: null,
                error: action.payload
            }
        case "GET_PARKING_PAYMENT_QR_RESET":
            return {
                ...state,
                getQRCodeData: null,
                error: null
            }
        default:
            return state
    }
}