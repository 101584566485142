import React, { useEffect, useRef } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import './PayParking.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetParkingPaymentQR } from '../../Redux/Action/Payment';
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from 'react-router-dom';
import { errorNotify } from '../../Util/Toast';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from '../../Util/Loader';

const BankPaymentStatus = () => {
    const qrCodeRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const getQueryParams = (search) => {
        const params = new URLSearchParams(search);
        return {
            // parkingTokenNumber: params.get('parkingTokenNumber'),
            parkingId: params.get('parkingId'),
            // status: params.get('status'),
        };
    };

    const { parkingId } = getQueryParams(location.search);

    const { loading, getQRCodeData } = useSelector((state) => state.parkingPaymentQRGet);

    useEffect(() => {
        if (parkingId) {
            const queryParams = {
                parkingId: parkingId,
            };
            dispatch(GetParkingPaymentQR(queryParams));
        }
        else {
            errorNotify("No Parking Id Found");
            navigate("/pay-parking");
        }

        return () => {
            dispatch({ type: "GET_PARKING_PAYMENT_QR_RESET" });
        }
    }, [])

    const handleDownloadPDF = async () => {
        if (qrCodeRef.current) {
            try {
                // Convert the QR code element to a canvas
                const canvas = await html2canvas(qrCodeRef.current);
                const imgData = canvas.toDataURL("image/png");

                const pdf = new jsPDF("p", "mm", "a4");

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const imgWidth = pdfWidth - 40; // Leave 20mm margin on each side
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                const x = (pdfWidth - imgWidth) / 2;
                const y = 20; // Adjust vertical position as needed
                pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);

                // Save the PDF
                pdf.save(`${parkingId}-qrcode.pdf`);
            } catch (error) {
                errorNotify("Error generating PDF: ", error)
            }
        }
    };

    return (
        <div className='pay_parking_main' style={{paddingTop: "110px"}}>
            <div className='container'>
                <div className='pay_parking_status'>
                    {
                        loading ? <div>
                            <h1>Payment Verifing...</h1>
                            <Loader />
                        </div> : <>
                            <div
                                ref={qrCodeRef}
                                style={{
                                    maxWidth: "700px", // Increased width for better resolution
                                    margin: 'auto',
                                    padding: "20px",
                                    textAlign: "center",
                                }}
                            >
                                <FaRegCheckCircle size={80} color="green" />
                                <h1 style={{ fontSize: "40px" }}>Transaction Successful</h1>
                                {getQRCodeData?.data && <QRCode value={JSON.stringify(getQRCodeData?.data)} size={300} />}
                            </div>

                            <div className='d-flex gap-3 align-items-center justify-content-center mt-3'>
                                <button onClick={handleDownloadPDF}>Download QR Code</button>
                                {/* <button onClick={() => navigate('/pay-parking')}>Pay New Payment</button> */}
                            </div>
                        </>
                    }

                    {/* {
                        !showQR ?
                            <button disabled={loading} onClick={() => setShowQR(true)}>{loading ? 'Loading...' : 'Preview QR'}</button> :
                            <button disabled={loading} onClick={() => setShowQR(false)}>{loading ? 'Loading...' : 'Hide QR'}</button>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default BankPaymentStatus