import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import businessFigure from "../../images/business_figures_icon.png";
import noOfDownloads from "../../images/no_of_downloads_icon.png";
import noOfUsers from "../../images/no_of_users_icon.png";
import noOfParkingSites from "../../images/parking_sites_icon.png";
import './BusinessStats.css';

const BusinessStats = () => {
    return (
        <div className='business_stats_main'>
            <Container>
                <Row className='justify-content-between'>
                    <Col md={5}>
                        <h3>Facilitating <br /> Businesses with <br /> Leading <span>Parking Solutions</span></h3>
                        <p>Enhancing efficiency with advanced parking solutions.</p>
                    </Col>
                    <Col md={6}>
                        <VisibilitySensor>
                            <Row>
                                <Col md={6} xs={6}>
                                    <div className='figure_box'>
                                        <img src={noOfUsers} alt='' />
                                        <div>
                                            <h5> <CountUp start={0} end={45341} duration={5} decimal="," /> </h5>
                                            <h6>No. of Users</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className='figure_box'>
                                        <img src={noOfParkingSites} alt='' />
                                        <div>
                                            <h5><CountUp start={0} end={98} duration={5} decimal="," /></h5>
                                            <h6>Parking Sites</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className='figure_box'>
                                        <img src={noOfDownloads} alt='' />
                                        <div>
                                            <h5><CountUp start={0} end={828867} duration={5} decimal="," /></h5>
                                            <h6>No. of Downloads</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className='figure_box'>
                                        <img src={businessFigure} alt='' />
                                        <div>
                                            <h5><CountUp start={0} end={1926436} duration={5} decimal="," /></h5>
                                            <h6>Business Figures</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </VisibilitySensor>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BusinessStats