import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Home from './Pages/Home/Home';
import PayParking from './Pages/PayParking/PayParking';
import Pricing from './Pages/Pricing/Pricing';
import Solutions from './Pages/Solutions/Solutions';
import TermsCondition from './Pages/TermsCondition/TermsCondition';
import ChoosePayment from './Pages/PayParking/ChoosePayment';
import BankPaymentStatus from './Pages/PayParking/BankPaymentStatus';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, hash]);
  return null;
}

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center" autoClose={3000} hideProgressBar={false}
        newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
        draggable pauseOnHover theme="light"
      />

      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/pay-parking" element={<PayParking />} />
        <Route path="/payment-method" element={<ChoosePayment />} />
        <Route path="/bankPaymentSatus" element={<BankPaymentStatus />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
export default App;